import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { CtxValidators, ResourceUpdateFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';
@Component({
  selector: 'ctx-update-assets-form',
  templateUrl: './update-assets-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class UpdateAssetsFormComponent extends CtxForm implements OnInit {
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData,
    public dialogRef: MatDialogRef<UpdateAssetsFormComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      logoUrl: [
        this.getInitialControlValue('logoUrl', this.dialogData),
        [CtxValidators.url],
      ],
      faviconUrl: [
        this.getInitialControlValue('faviconUrl', this.dialogData),
        [CtxValidators.url],
      ],
    });
  }
}
