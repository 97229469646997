import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { DataCacheService } from 'ng-ui';
import { CtxButtonComponent } from 'ng-ui';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-release-channel-form',
  templateUrl: './release-channel-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class ReleaseChannelFormComponent extends CtxForm implements OnInit {
  /** Resolved and cached products. */
  products = this.dataCacheService.getCachedValues('product');
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ReleaseChannelFormComponent>,
    public dataCacheService: DataCacheService
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      productId: [
        this.getInitialControlValue('productId', this.dialogData),
        Validators.maxLength(256),
      ],
      name: [
        this.getInitialControlValue('name', this.dialogData),
        Validators.maxLength(256),
      ],
      displayName: [
        this.getInitialControlValue('displayName', this.dialogData),
        Validators.maxLength(256),
      ],
      description: [
        this.getInitialControlValue('description', this.dialogData),
        Validators.maxLength(256),
      ],
      private: [this.getInitialControlValue('private', this.dialogData, false)],
    });
  }
}
