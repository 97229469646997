import { CryptlexApiService } from './cryptlex-api.service';
import { HttpResponse } from '@angular/common/http';
import { DataCacheService } from './data-cache.service';
import {
  ApiQueryParameters,
  RESOURCE_DEFINITIONS,
  ResourceName,
  convertKebabCaseToTitleCase,
  pluralizeResourceName,
} from 'utils';
import { PermissionsService } from './permissions.service';

/**
 * The ResourceService is a abstract service that holds basic logic for handling requests to the Web API
 * for an resource.
 */
export abstract class ResourceService<RequestModel = any, ResponseModel = any> {
  /**
   * This property is overriden in child classes to specify the API URL being used.
   * For example, the resource path will be `/v3/products` for the ProductService.
   */
  abstract resourceApiPath: string;

  /**
   * Defines the name of the resource the service is defined for.
   */
  abstract resourceName: ResourceName;

  /** Definition for the resource */
  get definition(): string {
    return RESOURCE_DEFINITIONS[this.resourceName];
  }

  /**
   * @returns A generic deletion warning based on the resourceDisplayName property.
   */
  getGenericDeletionMessage(count: number): string {
    return `Are you sure you want to delete the selected ${
      count > 1
        ? pluralizeResourceName(this.resourceDisplayName)
        : this.resourceDisplayName
    }?`;
  }

  /**
   * The resourceName property in Title Case for use in UIs
   */
  get resourceDisplayName(): string {
    return convertKebabCaseToTitleCase(this.resourceName);
  }

  constructor(
    public apiService: CryptlexApiService,
    public dataCacheService: DataCacheService,
    public permissionsService: PermissionsService
  ) {}

  /**
   * Create an resource object
   *
   * @param body Body of the resource object to create
   */
  create(body: RequestModel): Promise<HttpResponse<ResponseModel>> {
    return this.apiService.post(this.resourceApiPath, body).then((response) => {
      // Invalidates any in-memory cache held in the DataCacheService
      this.dataCacheService.invalidateCache(this.resourceName);
      return response;
    });
  }

  /**
   * Read specifics of a single resource object.
   *
   * @param id ID of the resource
   */
  read: ResourceReadFn<ResponseModel> = (id: string) => {
    return this.apiService.get(this.resourceApiPath.concat(`/${id}`));
  };

  /**
   * Update an resource object using a PATCH request.
   *
   * @param id ID of the resource to patch on the Web API
   * @param body Body of the changes to patch to the resource object
   */
  update(id: string, body: RequestModel): Promise<HttpResponse<ResponseModel>> {
    return this.apiService
      .patch(`${this.resourceApiPath}/${id}`, body)
      .then((response) => {
        // Invalidates any in-memory cache held in the DataCacheService
        this.dataCacheService.invalidateCache(this.resourceName);
        return response;
      });
  }

  /**
   * Delete resource by ID
   *
   * @param id ID of the resource
   */
  delete(id: string): Promise<HttpResponse<any>> {
    return this.apiService
      .delete(`${this.resourceApiPath}/${id}`)
      .then((response) => {
        // Invalidates any in-memory cache held in the DataCacheService
        this.dataCacheService.invalidateCache(this.resourceName);
        return response;
      });
  }

  /**
   * Returns a page of a paginated data set from the Web API
   * @param index Page number
   * @param size Page size
   * @param params Query parameters
   */
  list: ResourceListFn<ResponseModel> = (
    index: number,
    size: number,
    params?: ApiQueryParameters
  ): Promise<HttpResponse<ResponseModel[]>> => {
    return this.apiService.getList(this.resourceApiPath, index, size, params);
  };

  /**
   * Gets a CSV file for all the values matched by the paramaters.
   * @param params Query parameters
   */
  export(params: ApiQueryParameters): Promise<any> {
    return this.apiService
      .getRawData(`${this.resourceApiPath}/export`, params)
      .then((response) => {
        return response;
      });
  }

  /**
   * Delete metadata from the resource
   * WARNING: This function should only be used where metadata is present
   * */
  deleteMetadata(id: string) {
    return this.apiService.delete(`${this.resourceApiPath}/metadata/${id}`);
  }

  get writeAllowed() {
    return this.permissionsService.allowedAction(this.resourceName, 'write');
  }

  get readAllowed() {
    return this.permissionsService.allowedAction(this.resourceName, 'read');
  }

  get updateAllowed() {
    return this.permissionsService.allowedAction(this.resourceName, 'write');
  }

  get deleteAllowed() {
    return this.permissionsService.allowedAction(this.resourceName, 'write');
  }
}
export type ResourceReadFn<T> = (id: string) => Promise<HttpResponse<T>>;
export type ResourceListFn<T> = (
  page: number,
  size: number,
  params: ApiQueryParameters
) => Promise<HttpResponse<T[]>>;
