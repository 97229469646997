import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';

/** Gets parent page */
export const getParentPage = async (_state: RouterStateSnapshot) => {
  return _state.url.split('/').slice(0, -1).join('/');
};

/**
 * Standard method for generating a string for a QueryParam used within the dashboard using
 * ActivatedRoute or similar. This is mostly used for setting filters and other data across pages.
 * @param key Parameter name
 * @param data Data to encode in QueryParam
 * @returns An QueryParam object to pass to router.navigate([],{queryParams})
 */
export function generateRouterQueryParams(
  key: string,
  data: unknown
): Record<string, string> {
  const queryParams: Record<string, string> = {};
  queryParams[key] = encodeURIComponent(window.btoa(JSON.stringify(data)));
  return queryParams;
}

/**
 * Standard method to decode data from router QueryParams
 */
export function getDataFromRouterQueryParams(
  key: string,
  route: ActivatedRoute
): unknown {
  if (route.snapshot.queryParams[key]) {
    const _parsedFilterArray = JSON.parse(
      window.atob(decodeURIComponent(route.snapshot.queryParams[key]))
    );
    return _parsedFilterArray;
  } else {
    return undefined;
  }
}

/**
 * Used to reload the page after a dialog is closed.
 * @param afterClosed$
 * @param router
 * @param callback
 */
export async function reloadAfterSuccess(
  afterClosed$: Observable<any>,
  router: Router,
  callback?: () => void
) {
  const dialogResponse = await firstValueFrom(afterClosed$);
  if (dialogResponse) {
    if (callback) {
      callback();
    }
    await router.navigate([router.url]);
  }
}
