import { Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import {
  CollectionViewer,
  DataSource,
  SelectionModel,
} from '@angular/cdk/collections';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { CryptlexApiService } from './cryptlex-api.service';
import { ResourceService } from './resource.service';
import { DataCacheService } from './data-cache.service';

import {
  ApiQueryParameters,
  CtxFormMode,
  Filter,
  FilterableProperties,
  ResourceCreateFormData,
  ResourceDeleteFormData,
  ResourceExportFormData,
  ResourceUpdateFormData,
  TableActions,
  TableColumn,
  TableFilterQueryParam,
  TableProperties,
  TableSearchQueryParam,
  TableSortQueryParam,
  getResourceDisplayName,
  projectEnv,
} from 'utils';
import { OneStepDeletionComponent } from '../_forms/one-step-deletion/one-step-deletion.component';
import { CtxExportComponent } from '../_components/export/export.component';
import { CtxBatchTagFormComponent } from '../_forms/batch-tag-form/batch-tag-form.component';
import { PermissionsService } from './permissions.service';

export type DeletionComponentConfiguration = {
  singleResource: ComponentType<any>;
  multipleResource: ComponentType<any>;
};
/**
 * The ResourceTableService abstract class acts as a base-class to implement actions related to a paginated resource like Product, Feature Flag, etc.
 * The service implements the connect() and disconnect() functions to be used as a DataSource for Material CDK Table - based components.
 *
 * ### Steps to create a service with ResourceTableService
 *
 * 1. Extend the created service with ResourceTableService and override all abstract members of the class. This is necessary to create differentiation between services where required.
 * 2. Override **non-abstract members** where required. For example, the service being built might require a form to support the 'create' action, for which it is necessary to override the `creationComponent` property with the appropriate component.
 * 3. Since the ResourceTableService focuses on creating views for paginated entities, ensure that the columns, filterableProperties, etc. are correctly set by referencing the Web API documentation.
 *
 */
@Injectable()
export abstract class ResourceTableService
  extends ResourceService
  implements DataSource<any>, OnDestroy
{
  /**
   * Object defining permissible actions. This defines the table toolbar and the 'Actions' row in the table.
   * Note that actions already in the toolbar should not be repeated in the menuActions property.
   */
  abstract actions: TableActions;

  /** Key for table preferences */
  private get TABLE_PREFERENCE_KEY() {
    const account = this.dataCacheService.getCachedValues('account');
    return `${this.resourceName}TablePref/${account?.id}`;
  }

  /**
   * Returns an object to pass to HttpParams.appendAll() to apply search, sorting, and filters tot
   * the table results.
   */
  get tableApiQuery(): ApiQueryParameters {
    // TODO use default UrlSearchParams
    return {
      ...this.tableFilter,
      ...this.tableSearch,
      ...this.tableSort,
      ...this.includeCols,
    };
  }

  set filters(filters: Filter[]) {
    this._filters = filters;
    this.tablePageNumber = 1;

    // TEST THIS ASYNC CODE
    this.tableList();
  }
  get filters() {
    return this._filters;
  }
  /**
   * The filters that are currently active.
   */
  private _filters: Filter[] = [];

  /** True if update filter is allowed */
  isUpdateFilterAllowed(property: string): boolean {
    const FILTER_NOT_ALLOWED = [
      'activation-log',
      'audit-log',
      'automated-email-event-log',
      'webhook-event-log',
    ];
    return !FILTER_NOT_ALLOWED.includes(property);
  }

  /**
   * Returns TableFilterQueryParam object. Since multiple filters can be active on the same
   * filter property i.e, Name[eq] is allowed to have multiple filters, the generated
   * object contains the filter property as a key, and an array of strings as the corresponding
   * value to the key.
   */
  private get tableFilter(): TableFilterQueryParam {
    // Initial object definition for reduce function
    const queryObject: TableFilterQueryParam = {};

    // Iterates through activeFilters array
    return this._filters.reduce((prevValue, currValue) => {
      // Since the query params is generated as Name[eq]=Hello,
      // we set the key in the object to be 'Name[eq]' to be used correctly by
      // HttpParams.appendAll()
      const filterablePropertyType =
        this.filterableProperties[currValue.property];

      let queryParamKey: string;
      /**
       * For values that do not have operators i.e. Strings, Booleans, or simply query parameters
       * that are not also objects. **Do not add the operator to the query param**
       * The operators do not work for these query parameters.
       *
       * As a convention, we add the object suffix to any FilterablePropertyType that has object operators
       * */
      const isFilterablePropertyObject =
        /^object/.test(filterablePropertyType) ||
        /tag/.test(filterablePropertyType) ||
        /country/.test(filterablePropertyType) ||
        /resourceId/.test(filterablePropertyType) ||
        /resource/.test(filterablePropertyType) ||
        /role/.test(filterablePropertyType) ||
        /automated-email/.test(filterablePropertyType) ||
        /webhook/.test(filterablePropertyType) ||
        /product/.test(filterablePropertyType) ||
        /product-version/.test(filterablePropertyType) ||
        /licenseType/.test(filterablePropertyType) ||
        /note/.test(filterablePropertyType) ||
        /os/.test(filterablePropertyType);
      if (isFilterablePropertyObject) {
        queryParamKey = currValue.property.concat(`[${currValue.operator}]`);
      } else {
        queryParamKey = currValue.property;
      }
      /**
       *  We can create a copy of prevValue to mutate on but for this function
       *  this should suffice without creating issues.
       */
      if (!prevValue[queryParamKey]) {
        prevValue[queryParamKey] = [];
      }

      const queryParamValue = currValue.value;
      // If the filterablePropertyType is objectDate, and the operator is eq, we need to
      // add a query parameters to the object with the same property name, but with
      // the operator 'gt' and the operator 'lt', with currValue.value and next day date value respectively.
      if (
        filterablePropertyType === 'objectDate' &&
        currValue.operator === 'eq' &&
        typeof queryParamValue == 'string'
      ) {
        const originalDate = new Date(queryParamValue);
        const modifiedDate = new Date(
          originalDate.setDate(originalDate.getDate() + 1)
        ).toISOString();
        prevValue[currValue.property.concat(`[gt]`)] = [queryParamValue];
        prevValue[currValue.property.concat(`[lt]`)] = [modifiedDate];
      } else if (
        typeof queryParamValue == 'string' ||
        typeof queryParamValue === 'number'
      ) {
        prevValue[queryParamKey].push(queryParamValue);
      } else if (Array.isArray(queryParamValue)) {
        prevValue[queryParamKey].push(queryParamValue.join(','));
      }

      return prevValue;
    }, queryObject);
  }

  /** Some resources depend on a number of resources to be created. This property is overriden wherever required and
   * permission to read dependencies is checked first.
   */
  get createAllowed() {
    return this.writeAllowed;
  }

  /**
   * These properties define the query made to the API to fetch columns for the table. Since these properties are private,
   * they are accessed using setters and getters to allow custom code to execute when setting these properties.
   */
  private tableProperties: TableProperties = {
    filter: {},
    search: { query: '' },
    sort: { sort: '-createdAt' },
    pageNumber: 1,
    pageSize: 20,
    rowCount: 0,
  };

  /**
   * Sets the `sort` property in tableProperties
   */
  set tableSort(sortObject: TableSortQueryParam) {
    this.tablePageNumber = 1;
    this.tableProperties.sort = sortObject;
  }

  /**
   * Gets the `sort` property from tableProperties
   */
  get tableSort() {
    return this.tableProperties.sort;
  }

  /**
   * Sets the `search` property in tableProperties
   */
  set tableSearch(searchObject: TableSearchQueryParam) {
    this.tablePageNumber = 1;
    this.tableProperties.search = searchObject;
  }

  /**
   * Gets the `search` property from tableProperties
   */
  get tableSearch(): TableSearchQueryParam {
    return this.tableProperties.search;
  }

  /**
   * Sets the `pageNumber` property in tableProperties
   */
  set tablePageNumber(page: number) {
    this.tableProperties.pageNumber = page;
  }

  /**
   * Gets the `pageNumber` property from tableProperties
   */
  get tablePageNumber() {
    return this.tableProperties.pageNumber;
  }

  /**
   * Sets the `pageSize` property in tableProperties
   */
  set tablePageSize(pageSize: number) {
    this.tableProperties.pageSize = pageSize;
  }

  /**
   * Gets the `pageSize` property from tableProperties
   */
  get tablePageSize() {
    return this.tableProperties.pageSize;
  }

  // get tableUserPreferences(): TableUserPreferences {
  //   return {
  //     columns: this.columnIdsToDisplay,
  //     pageSize: this.tablePageSize,
  //     sort: this.tableSort,
  //   };
  // }

  /**
   * Gets the `rowCount` property from tableProperties
   */
  get tableRowCount() {
    return this.tableProperties.rowCount;
  }

  /**
   * Returns the pageSizeOptions array for the Paginator
   */
  get tablePageSizeOptions() {
    return [10, 20, 30, 50, 100];
  }

  /**
   * Returns true if any table filters are active currently.
   */
  get isFilterActive(): boolean {
    return this.filters.length !== 0;
  }

  /**
   * True if the search parameter is not empty
   */
  get isSearchActive(): boolean {
    return this.tableProperties.search.query !== '';
  }

  /** Error message for table, the value can be null so that tableEmptyMessages and tableErrorMessages are separated by logic */
  tableErrorMessage: string | null = null;

  /** Placeholder text displayed in the searchbar in the table toolbar */
  abstract tableSearchPlaceholder: string;

  /** Message to show when table is empty  */
  abstract tableEmptyMessage: string;

  /**
   * SelectionModel object that defines the selections made in the table using checkboxes
   */
  abstract selections: SelectionModel<any>;

  /**
   * Behaviour subject for table loading state. This property can be shared across all classes.
   */
  tableLoading = new BehaviorSubject<boolean>(true);

  /**
   * Observable for table loading state. Used publicly in templates.
   */
  tableLoading$ = this.tableLoading.asObservable();

  /**
   * This data-source provides data to the mat-table component.
   *
   * As of now, this dataSource is shared among services.
   *
   */
  dataSource = new BehaviorSubject<any[]>([]);

  /**
   * List of columns that exist for this resource. This helps us populate the list of columns that can be shown/hidden.
   * Property must be overriden for all entities manually, or using the Web API on runtime.
   */
  abstract columns: TableColumn[];

  /**
   * The TableColumn.property is used to access the data in response object and the column id is set in the table
   * is always equal to the substring before the '.', if any, stored in TableColumn.property
   */
  get allColumnIds(): string[] {
    return this.columns.map((tableColumn: TableColumn) => {
      return tableColumn.property;
    });
  }
  /**
   * This array holds the columns that are displayed in the table out of the total columns available for viewing.
   * This property should be treated as a private member.
   */
  abstract _columnIdsToDisplay: string[];

  set columnIdsToDisplay(columnIds: string[]) {
    this._columnIdsToDisplay = columnIds;
    this.saveTablePreferences();
    /** if include query is not empty we have to make an api call */
    if (this.includeCols.include) {
      this.tableList();
    }
  }

  get columnIdsToDisplay() {
    const columnIdsWithDefaults = [...this._columnIdsToDisplay];

    // Always display createdAt and updatedAt before Actions and after all other columns
    if (!columnIdsWithDefaults.includes('createdAt')) {
      columnIdsWithDefaults.push('createdAt');
    }
    return columnIdsWithDefaults;
  }

  creationComponent: ComponentType<any>;
  /**
   * Assign components to the dialog that is triggered when `launchDeleteDialog()` is called.
   * The singleDelete and multipleDelete allow you to set separate components for deletion of single resource
   * or multiple entities.
   */
  deletionComponent: DeletionComponentConfiguration = {
    singleResource: OneStepDeletionComponent,
    multipleResource: OneStepDeletionComponent,
  };
  updationComponent: ComponentType<any>;

  /** Component that is launched within a dialog when export button is pressed. */
  exportComponent: ComponentType<any> = CtxExportComponent;

  /** Override if custom deletion message is required. */
  deletionMessage: string;

  /**
   * Object of `{filterProperty: filterPropertyType;}` for the columns of the resource on which filters can be applied. These are always known before runtime and are
   * determined by actions supported in the Web API.
   */
  abstract filterableProperties: FilterableProperties;

  /**
   * Returns array of filterProperties as defined in the `filterableColumns` object.
   */
  get filterablePropertyIds(): string[] {
    if (!('createdAt' in this.filterableProperties)) {
      this.filterableProperties['createdAt'] = 'objectDate';
    }
    if (
      !('updatedAt' in this.filterableProperties) &&
      this.isUpdateFilterAllowed(this.resourceName)
    ) {
      this.filterableProperties['updatedAt'] = 'objectDate';
    }
    // TODO memoize this
    return Object.keys(this.filterableProperties).sort(
      (a: string, b: string) => {
        return getResourceDisplayName(
          a,
          projectEnv.get('projectName')
        ).localeCompare(
          getResourceDisplayName(b, projectEnv.get('projectName'))
        );
      }
    );
  }

  constructor(
    apiService: CryptlexApiService,
    public dialog: MatDialog,
    dataCacheService: DataCacheService,
    public router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dataCacheService, permissionsService);
  }

  /** Fetches any saved table properties from localStorage using localforage.  */
  getTablePreferences() {
    const value = localStorage.getItem(this.TABLE_PREFERENCE_KEY);
    if (value && typeof value === 'string') {
      const _object = JSON.parse(value);
      if (
        'tableSort' in _object &&
        'tablePageSize' in _object &&
        'columnIdsToDisplay' in _object
      ) {
        this.tableSort = _object.tableSort;
        this.tablePageSize = _object.tablePageSize;
        this.columnIdsToDisplay = _object.columnIdsToDisplay.filter(
          (columnId: string) => {
            return this.columns.find((column: TableColumn) => {
              return column.property === columnId;
            });
          }
        );
      }
    }
  }

  /** columns that be sent in include query */
  colsAllowedInIncludeQuery: string[] = [];

  /** query for include, coverts columnIds array to comma seperated string, also converts resource.propery -> resource */
  private get includeCols() {
    return {
      include: this.colsAllowedInIncludeQuery
        .filter((col: string) => {
          return this.columnIdsToDisplay.find((colId: string) => {
            return colId.split('.')[0] === col;
          });
        })
        .join(','),
    };
  }

  /** Saves sorting, columns, and page size in localStorage using localforage. */
  saveTablePreferences() {
    localStorage.setItem(
      this.TABLE_PREFERENCE_KEY,
      JSON.stringify({
        tableSort: this.tableSort,
        tablePageSize: this.tablePageSize,
        columnIdsToDisplay: this.columnIdsToDisplay,
      })
    );
  }

  /**
   * This function allows us to use the service as a DataSource. As defined in the ResourceTableService comments, the connect() and
   * disconnect() functions are an interface for MatTable components.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.dataSource.asObservable();
  }

  /**
   * This function allows us to use the service as a DataSource. As defined in the ResourceTableService comments, the connect() and
   * disconnect() functions are an interface for MatTable components.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disconnect(collectionViewer: CollectionViewer): void {
    return;
  }

  /**
   * Launches a dialog with the set creationForm for the current resource. Allows the user to create
   * the resource.
   */
  async launchCreationDialog() {
    if (this.creationComponent) {
      const _dialog = this.dialog.open<any, ResourceCreateFormData>(
        this.creationComponent,
        {
          data: {
            mode: CtxFormMode.Create,
            create: this.create.bind(this),
          },
        }
      );
      await this.handleDialogClose(_dialog);
    }
  }
  /**
   * Lauches a tag picker dialog
   */
  async launchTagPicker(resource: any) {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      CtxBatchTagFormComponent,
      {
        data: {
          mode: CtxFormMode.Update,
          update: this.update.bind(this),
          resource,
        },
      }
    );
    await this.handleDialogClose(_dialog);
  }

  /**
   * Launches a dialog with a generic export dialog.
   */
  async launchExportDialog() {
    if (this.exportComponent) {
      this.dialog.open<any, ResourceExportFormData>(this.exportComponent, {
        data: {
          resourceName: this.resourceName,
          export: this.export.bind(this),
          query: this.tableApiQuery,
          filters: this.filters,
          tableRowCount: this.tableRowCount,
        },
      });
    }
  }

  /**
   * Launches a dialog with the set updationForm for the current resource. Allows the user to update
   * the resource.
   */
  async launchUpdationDialog(resource: any) {
    if (this.updationComponent) {
      const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
        this.updationComponent,
        {
          data: {
            mode: CtxFormMode.Update,
            resource,
            update: this.update.bind(this),
          },
        }
      );

      await this.handleDialogClose(_dialog);
    }
  }
  /**
   * This function decides based on factors within the resource Service state which Deletion dialog to launch
   * @param resource resource object (optional)
   */
  async launchDeletionDialog(resource?: any) {
    if (resource) {
      await this.launchSingleDeletionDialog(resource);
    } else if (this.selections.selected.length === 1) {
      await this.launchSingleDeletionDialog(this.selections.selected[0]);
    } else if (this.selections.selected.length > 1) {
      await this.launchMultipleDeletionDialog();
    } else {
      /**
       * This should never happen.
       * This Error is caught by Bugsnag and should be fixed by checking how 'resource' parameter is passed, if it ever occurs.
       */
      throw Error(
        'Deletion has been setup incorrectly. Please check the resource service.'
      );
    }
    // Clears selections object.
    this.clearTableSelections();
  }

  /**
   * Launch the defined component for deleting a single resource.
   *
   * @param resource ID of resource to delete
   */
  async launchSingleDeletionDialog(resource: any[]) {
    if (this.deletionComponent.singleResource) {
      /** Coerce resource to array */
      const resourceArray = Array.isArray(resource) ? resource : [resource];

      const singleDeleteDialog = this.dialog.open<any, ResourceDeleteFormData>(
        this.deletionComponent.singleResource,
        {
          maxWidth: '390px',
          data: {
            resources: resourceArray,
            delete: this.delete.bind(this),
            deletionMessage:
              this.deletionMessage ||
              this.getGenericDeletionMessage(resourceArray.length),
          },
        }
      );

      await this.handleDialogClose(singleDeleteDialog, true);
    }
  }

  /**
   * Launch the defined component for deleting the multiple entities selected in a table.
   */
  async launchMultipleDeletionDialog() {
    if (this.deletionComponent.multipleResource) {
      const multipleDeleteDialog = this.dialog.open<
        any,
        ResourceDeleteFormData
      >(this.deletionComponent.multipleResource, {
        maxWidth: '390px',
        data: {
          resources: this.selections.selected,
          delete: this.delete.bind(this),
          deletionMessage:
            this.deletionMessage ||
            this.getGenericDeletionMessage(this.selections.selected.length),
        },
      });

      await this.handleDialogClose(multipleDeleteDialog, true);
    }
  }

  /**
   * If the dialog form returns true, resets the table page number, fetches table data again,
   * and reloads the page if on an resource page.
   */
  async handleDialogClose(dialog: MatDialogRef<any>, isDelete?: boolean) {
    const dialogResponse = await firstValueFrom(dialog.afterClosed());
    if (dialogResponse) {
      const ENDS_WITH_GUID_REGEX =
        /(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/;
      // If deletion is done from resource page, refresh page
      if (ENDS_WITH_GUID_REGEX.test(this.router.url)) {
        const previousUrl = this.router.url.split('/').slice(0, -1).join('/');
        await this.router.navigate([isDelete ? previousUrl : this.router.url]);
      } else {
        // Else, reset the table.
        // The current table page might end up empty. Set current page to 1.
        this.tableProperties.pageNumber = 1;
        await this.tableList();
      }
    }
  }

  /**
   * This function is used to list items in the paginated MatTable. It toggles the loader,
   * set the error message if found, fetches data as per the table's parameters, and passes
   * it via the `dataSource` object.
   */
  async tableList() {
    this.tableLoading.next(true);
    try {
      // Clear the selections object
      this.clearTableSelections();

      const response = await this.list(
        this.tableProperties.pageNumber,
        this.tableProperties.pageSize,
        this.tableApiQuery
      );
      // Reset error message
      this.tableErrorMessage = null;
      // Set total count of table data
      this.tableProperties.rowCount = parseInt(
        response.headers.get('Pagination-Count') || '0'
      );
      this.dataSource.next(response.body ? response.body : []);
      this.tableLoading.next(false);
    } catch (errorResponse: any) {
      this.tableLoading.next(false);
      this.setTableErrorMessage(errorResponse);
    }
  }

  /**
   * Generic error handler for retrieving error messages for the table. It mutates the tableErrorMessage
   * property.
   * @param errorResponse Error object received after communication with the Cryptlex Web API
   */
  setTableErrorMessage(errorResponse: any) {
    if (errorResponse.error && errorResponse.error.message) {
      this.tableErrorMessage = errorResponse.error.message;
    } else if (errorResponse.message) {
      this.tableErrorMessage = errorResponse.message;
    } else {
      // eslint-disable-next-line no-console
      console.error(errorResponse);
    }
  }

  /**
   * Returns true when all displayed rows of the table are selected.
   */
  get allTableRowsSelected() {
    const numSelected = this.selections.selected.length;
    const numRows = this.dataSource.value.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllTableRows() {
    this.allTableRowsSelected
      ? this.selections.clear()
      : this.dataSource.value.forEach((row: any) => {
          return this.selections.select(row);
        });
  }

  /**
   * Clears all selections made in the table.
   */
  clearTableSelections() {
    if (this.selections) {
      return this.selections.clear();
    }
  }

  /**
   * The table error once set does not disappear automatically unless the table is refreshed
   * Low priority errors can be made to disappear automatically. This function is a default method
   * to reset the `tableErrorMessage` property.
   * @param ms Timeout in milliseconds
   */
  resetTableErrorAfterTimeout(ms = 5000) {
    setTimeout(() => {
      this.tableErrorMessage = null;
    }, ms);
  }

  ngOnDestroy() {
    this.dataSource.complete();
    this.tableLoading.complete();
  }
}
