<h2 mat-dialog-title>
  {{ dialogData.mode === 'Update' ? 'Update' : 'Configure' }} SAML SSO
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}

<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Entity ID -->
      <section>
        <h3>Entity ID</h3>
        <span class="flex items-center gap-2">
          <code>{{ entityId }}</code>
          <button
            ctx-button--secondary
            (click)="copyToClipboard(entityId, entityIdTooltip)"
            actionIcon="COPY"
            #entityIdTooltip="matTooltip"
            matTooltip="Entity ID Copied."
            [matTooltipDisabled]="true"
            type="button"
          ></button>
        </span>
        <p>Paste this into your SSO identity provider's configuration page</p>
      </section>

      <!-- Assertion Consumer Service URL -->
      <section>
        <h3>Assertion Consumer Service URL</h3>
        <span class="flex items-center gap-2">
          <code>{{ acsUrl }}</code>
          <button
            ctx-button--secondary
            (click)="copyToClipboard(acsUrl, acsUrlToolTip)"
            actionIcon="COPY"
            #acsUrlToolTip="matTooltip"
            matTooltip="ACS URL Copied."
            [matTooltipDisabled]="true"
            type="button"
          ></button>
        </span>
        <p>Paste this into your SSO identity provider's configuration page</p>
      </section>
      <hr />
      <!-- Return Urls -->
      <mat-form-field>
        <mat-label>Return URL</mat-label>
        <mat-select formControlName="returnUrl" required>
          @for (url of returnUrls; track url) {
          <mat-option class="ph-no-capture" [value]="url">
            {{ url }}
          </mat-option>
          }
        </mat-select>
        <mat-hint
          >Paste this into your SSO identity provider's configuration
          page</mat-hint
        >
      </mat-form-field>

      <!-- MetadataUrl -->
      <mat-form-field>
        <mat-label>SAML idP Metadata URL</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="metadataUrl"
          matInput
          required
        />
        <mat-hint>From your SSO identity provider</mat-hint>
      </mat-form-field>
      <!-- Auto-Provision Users -->
      <mat-checkbox
        formControlName="autoProvisionUsers"
        (change)="createRoleMapping($event.checked)"
        >Auto-Provision Users on First Login
      </mat-checkbox>

      <section formArrayName="samlRoleMappings" class="flex flex-col gap-4">
        @for ( samlRoleMapping of samlRoleMappingsFields.controls; track
        samlRoleMapping; let index = $index) {
        <span class="flex flex-row gap-2 items-start" [formGroupName]="index">
          <!-- Service Provider Role -->
          <mat-form-field class="flex-grow">
            <mat-label>Service Provider Role</mat-label>
            <mat-select formControlName="serviceProviderRole" required>
              @for (role of roles; track role) {
              <mat-option class="ph-no-capture" [value]="role.name">
                {{ role.name }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{
              getControlError(samlRoleMapping, 'serviceProviderRole')
            }}</mat-error>
          </mat-form-field>
          <!-- Identity Provider Role -->
          <mat-form-field class="flex-grow">
            <mat-label>Identity Provider Role</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="identityProviderRole"
              matInput
              required
            />
            <mat-error>{{
              getControlError(samlRoleMapping, 'identityProviderRole')
            }}</mat-error>
          </mat-form-field>
          <!-- Role mapping delete -->
          <button
            ctx-button--secondary
            type="button"
            color="accent"
            (click)="removeFormArrayField(samlRoleMappingsFields, index)"
            actionIcon="CLEAR"
            [disabled]="
              this.formGroup.get('autoProvisionUsers')?.value &&
              this.samlRoleMappingsFields.length === 1
            "
          ></button>
        </span>
        }
      </section>
      <!-- Add Role Mappings -->
      @if (this.formGroup.get('autoProvisionUsers')?.value) {
      <button
        ctx-button--secondary
        type="button"
        actionIcon="CREATE"
        class="self-center"
        (click)="
          addFormArrayField(samlRoleMappingsFields, _createNewSamlRoleMapping())
        "
      >
        Add Role Mappings
      </button>
      }
    </section>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="this.formStates['submit']"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode === 'Update' ? 'Update' : 'Save' }}
    </button>
  </mat-dialog-actions>
</form>
