<mat-form-field class="w-full">
  <mat-label>Tags</mat-label>
  <mat-select
    multiple
    [disabled]="tags.length === 0"
    (selectionChange)="handleTagSelection($event)"
    [formControl]="tagControl"
    [value]="control?.value"
  >
    @for (tag of tags; track tag) {
    <mat-option class="ph-no-capture" [value]="tag.name">
      {{ tag.name }}
    </mat-option>
    }
  </mat-select>
  @if (tags.length) {
  <mat-hint>A maximum of 5 tags are allowed.</mat-hint>
  } @if (!tags.length) {
  <mat-hint
    >No tags have been created. Go to the tags page to create one.</mat-hint
  >
  } @if (tagControl.errors?.['message']) {
  <mat-error>
    {{ tagControl.errors?.['message'] }}
  </mat-error>
  }
</mat-form-field>
