import { Title } from '@angular/platform-browser';
import { CustomConfigKeys } from '../types/_exports';
import { setThemeHue } from './theme';

/**
 * Get value from localstorage
 * @returns
 */
export function getCustomConfigValue(key: CustomConfigKeys) {
  return localStorage.getItem(key);
}

export const setCustomFavicon = () => {
  const faviconUrl = getCustomConfigValue('faviconUrl');
  if (faviconUrl) {
    document.getElementById('favicon')?.setAttribute('href', faviconUrl);
  }
};

export const setTitle = (titleService: Title) => {
  const customCompany = getCustomConfigValue('company');
  const previousTitle = titleService.getTitle();
  if (customCompany && previousTitle.endsWith('| Cryptlex')) {
    /** Ideally should have done in getRouteTitle function but no browser or server check can be done there*/
    const newTitle = previousTitle.replace('Cryptlex', customCompany);
    titleService.setTitle(newTitle);
  }
};
export const setCustomHue = () => {
  const hue = getCustomConfigValue('webPortalTheme');
  if (hue) {
    setThemeHue(Number(hue));
  }
};
