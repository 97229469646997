import { Component, afterNextRender } from '@angular/core';
import { initializeGoogleAnalytics, initializeIntercom } from 'ng-ui';
import { ScriptService, ThemeManagerService } from 'ng-ui';
import { initializePosthog } from 'utils';
import { AppUpdateService } from './services/app-update.service';
import { environment, getDashboardVersion, projectEnv } from 'utils';
import { RouterOutlet } from '@angular/router';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';

@Component({
  selector: 'ctx-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(
    public themeManager: ThemeManagerService,
    private scriptService: ScriptService,
    private appUpdateService: AppUpdateService,
    private angulartics: Angulartics2GoogleGlobalSiteTag
  ) {
    afterNextRender(async () => {
      initializeGoogleAnalytics(this.scriptService, this.angulartics);
      initializeIntercom(
        projectEnv.get('projectName'),
        environment.get('name'),
        getDashboardVersion(),
        this.scriptService,
        this.themeManager
      );
      initializePosthog({
        apiKey: projectEnv.get('posthogApiKey'),
        apiHost: projectEnv.get('posthogApiHost'),
      });
      this.appUpdateService.checkForUpdate();
    });
  }
}
