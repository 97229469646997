import { APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_ICON_DEFAULT_OPTIONS } from '@angular/material/icon';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatNativeDateModule } from '@angular/material/core';

import {
  HttpClientModule,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { PersistentStore } from './_services/persistent-store.service';
import { environment } from 'utils';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

import { BaseRouteReuseStrategy, RouteReuseStrategy } from '@angular/router';
import { ConfigurationService } from './_services/configuration.service';
import { Angulartics2Module } from 'angulartics2';
import { bugsnagErrorHandlerFactory } from './_utils/bugsnag';

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(): boolean {
    return false;
  }
}

const CTX_FORM_FIELD_DEFAULTS: Provider = {
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: {
    floatLabel: 'always',
    appearance: 'outline',
    subscriptSizing: 'dynamic',
  },
};

const CTX_DIALOG_DEFAULTS: Provider = {
  provide: MAT_DIALOG_DEFAULT_OPTIONS,
  useValue: {
    minWidth: '30vw',
    maxHeight: '90vh',
    maxWidth: '100ch',
    autoFocus: 'dialog',
    panelClass: ['p-4', 'w-full'],
  },
};

const CTX_RIPPLE_DEFAULTS: Provider = {
  provide: MAT_RIPPLE_GLOBAL_OPTIONS,
  useValue: { disabled: true },
};

const CTX_ICON_DEFAULTS: Provider = {
  provide: MAT_ICON_DEFAULT_OPTIONS,
  useValue: { fontSet: 'material-symbols-rounded' },
};

const CTX_CHECKBOX_DEFAULTS: Provider = {
  provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
  useValue: {
    color: 'accent',
  },
};

const CTX_SPINNER_DEFAULTS: Provider = {
  provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
  useValue: {
    color: 'accent',
    diameter: 24,
    strokeWidth: 2,
  },
};

/**
 * Formatting string taken by the Date pipe
 */
export const DEFAULT_DATE_FORMAT_STRING = 'MMM dd, yyy hh:mm:ss a';

const CTX_DATE_PIPE_DEFAULTS: Provider = [
  {
    provide: DATE_PIPE_DEFAULT_OPTIONS,
    useValue: {
      dateFormat: DEFAULT_DATE_FORMAT_STRING,
    },
  },
];

/** Array of Providers used in every CTX web app  */
export const CTX_DEFAULT_PROVIDERS = [
  CTX_FORM_FIELD_DEFAULTS,
  CTX_DIALOG_DEFAULTS,
  CTX_RIPPLE_DEFAULTS,
  CTX_ICON_DEFAULTS,
  CTX_CHECKBOX_DEFAULTS,
  CTX_SPINNER_DEFAULTS,
  CTX_DATE_PIPE_DEFAULTS,
  provideHttpClient(withInterceptorsFromDi(), withFetch()),
  provideAnimationsAsync(),
  provideClientHydration(),
  {
    provide: RouteReuseStrategy,
    useClass: CustomRouteReuseStrategy,
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (config: ConfigurationService) => {
      return () => {
        return config.getAndSetConfig();
      };
    },
    deps: [ConfigurationService],
  },
  {
    provide: ErrorHandler,
    useFactory: bugsnagErrorHandlerFactory,
  },
];

/** Factory set up for dynamically setting allowed domains */
export function jwtOptionsFactory(options: {
  tokenGetter: () => string | null;
  allowedDomains: string[];
}) {
  const whitelist = options.allowedDomains || [];

  /** Function for dynamically adding domains */
  function addDomainToAllowedDomains(domain: string) {
    if (!whitelist.includes(domain)) {
      whitelist.push(domain);
    }
  }

  return {
    addDomainToAllowedDomains,
    options: () => {
      return {
        ...options,
        allowedDomains: whitelist,
      };
    },
  };
}

export const jwtOptions = jwtOptionsFactory({
  tokenGetter: PersistentStore.getAccessToken,
  allowedDomains: environment.get('allowedDomains'),
});

/** Array of Modules used in every CTX web app */
export const CTX_DEFAULT_MODULE_IMPORTS = [
  BrowserModule,
  HttpClientModule,
  MatDialogModule,
  MatNativeDateModule,
  Angulartics2Module.forRoot({
    pageTracking: {
      clearIds: true,
    },
  }),
  JwtModule.forRoot({
    jwtOptionsProvider: {
      provide: JWT_OPTIONS,
      useFactory: jwtOptions.options,
    },
  }),
];
