import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ResourceName } from 'utils';
import { FilterableProperties } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { FeatureFlagFormComponent } from './feature-flag-form/feature-flag-form.component';
import { SEARCH_STRINGS } from 'utils';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService extends ResourceTableService {
  override resourceApiPath = '/v3/feature-flags';
  override resourceName: ResourceName = 'feature-flag';
  override tableSearchPlaceholder = SEARCH_STRINGS.name;

  override actions: TableActions = {
    create: true,
    delete: true,
    read: true,
    search: true,
    tag: false,
    update: true,
    export: false,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.createAllowed;
        },
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };

  override get createAllowed() {
    return (
      this.writeAllowed &&
      this.permissionsService.allowedAction('product', 'read')
    );
  }
  override creationComponent = FeatureFlagFormComponent;
  override updationComponent = FeatureFlagFormComponent;

  override columns: TableColumn[] = [
    { property: 'name', displayType: 'name' },
    { property: 'description', displayType: 'truncateText' },
    { property: 'productId', displayType: 'product' },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];

  override filterableProperties: FilterableProperties = {
    productId: 'product',
    name: 'objectString',
  };

  override _columnIdsToDisplay: string[] = ['name', 'description', 'productId'];
  override tableEmptyMessage =
    'Feature Flags make up Product Versions. These flags define the differentiators within different flavours of your Product.';

  override selections: SelectionModel<any> = new SelectionModel<any>(true, []);

  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }
}
