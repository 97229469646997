export const API_BASE_URL_EU = 'https://api.eu.cryptlex.com';
export const API_BASE_URL_US = 'https://api.cryptlex.com';
export const API_BASE_URL_IN = 'https://api.dev.cryptlex.com';

export const RELEASE_BASE_URL_US = 'https://releases.cryptlex.com';
export const RELEASE_BASE_URL_IN = 'https://releases.dev.cryptlex.com';
export const RELEASE_BASE_URL_EU = 'https://releases.eu.cryptlex.com';

export const STRIPE_API_KEY_PROD = 'pk_live_ucK2xacdfNiyX4Q1JMGLXdwh';
export const STRIPE_API_KEY_DEV = 'pk_test_duxJ6mzAYCdO2VtmaUE2ZmWv';

export const GOOGLE_CLIENT_ID =
  '564989091024-3i5einfqvu6nkribl2nocv7nkjjas8ls.apps.googleusercontent.com';
export const RECAPTCHA_SITE_KEY = '6Lfo4bEaAAAAABWxs-Z3fAdXQ8SJmN-FztzDXjQy';

export const POSTHOG_API_KEY =
  'phc_Ib72c5ovN7A1eqOUEQUiaPRBd5KGLXiDQLBYNisEd75';
export const POSTHOG_API_HOST = 'https://insights.cryptlex.com';

export const PADDLE_VENDOR_ID_PROD = 151192;
export const PADDLE_VENDOR_ID_DEV = 7084;

export const PADDLE_TOKEN_PROD = 'live_67b9793bdee2e0a211877a6a3f5';
export const PADDLE_TOKEN_DEV = 'test_e685fa7e89302128d22b993a292';

export const CRYPTLEX_PORTAL_URL = 'https://app.cryptlex.com';

export const AdminPortalHostingDomain = 'hosting.app.cryptlex.com';
export const CustomerPortalHostingDomain = 'hosting.customer.cryptlex.com';
export const EuCustomerPortalHostingDomain = 'hosting.customer.eu.cryptlex.com';

export const ResellerPortalHostingDomain = 'hosting.reseller.cryptlex.com';
export const EuResellerPortalHostingDomain = 'hosting.reseller.eu.cryptlex.com';

export const DevResellerPortalHostingDomain =
  'hosting.reseller.dev.cryptlex.com';
export const DevCustomerPortalHostingDomain =
  'hosting.customer.dev.cryptlex.com';
