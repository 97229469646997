export const MAX_INT32 = 2147483647;
export const MAX_INT32_DAYS = Math.floor(MAX_INT32 / 86400);
export const MIN_INT32 = -2147483648;
export const MIN_INT32_DAYS = Math.ceil(MIN_INT32 / 86400);
/**
 * Returns true if the string is a valid SemVer formatted version
 */
export function isVersion(version: string): boolean {
  const regex = /^$|^\d+(\.\d+)*$/;
  return regex.test(version);
}

/**
 * Returns true if the URL param is a Valid HTTPS URL
 */
export function isValidUrl(url: string): boolean {
  let newUrl;

  try {
    newUrl = new URL(url);
  } catch {
    return false;
  }

  return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
}
