<h2 mat-dialog-title>{{ dialogData.mode }} License Template</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formValue, dialogData, dialogRef, tab)"
>
  <mat-dialog-content>
    <ctx-tab-group #tab>
      <ctx-tab label="Details" icon="DETAILS" [stepControl]="detailsTab">
        <section class="flex flex-col gap-4">
          <!-- Name -->
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="name"
              matInput
            />
            <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
            <mat-hint>Name of the license template.</mat-hint>
          </mat-form-field>

          <!-- Type -->
          <mat-form-field>
            <mat-label>License Type</mat-label>
            <mat-select formControlName="type">
              <mat-option value="node-locked">Node Locked</mat-option>
              <mat-option value="hosted-floating">Hosted Floating</mat-option>
              <mat-option value="on-premise-floating"
                >On-Premise Floating (LexFloatServer)</mat-option
              >
            </mat-select>
            @if (formGroup.value.type === 'node-locked') {
            <mat-hint>Locks the license activation to the machine.</mat-hint>
            } @if (formGroup.value.type === 'hosted-floating') {
            <mat-hint>Floating license for online environments</mat-hint>
            } @if (formGroup.value.type === 'on-premise-floating') {
            <mat-hint
              >License for LexFloatServer which allows offline floating
              licenses.</mat-hint
            >
            }
            <mat-error>{{ getControlError(formGroup, 'type') }}</mat-error>
          </mat-form-field>
          @if (selectedLicenseType === 'hosted-floating') {
          <!-- Leasing Strategy -->
          <mat-form-field>
            <mat-label>Leasing Strategy</mat-label>
            <mat-select formControlName="leasingStrategy" required>
              <mat-option value="per-machine">Per-Machine</mat-option>
              <mat-option value="per-instance">Per-Instance</mat-option>
            </mat-select>
            <mat-error>{{
              getControlError(formGroup, 'leasingStrategy')
            }}</mat-error>
          </mat-form-field>
          <!-- Lease Duration -->
          <mat-form-field>
            <mat-label>Lease Duration</mat-label>
            <input
              autocomplete="off"
              type="number"
              formControlName="leaseDuration"
              matInput
            />
            <span matSuffix>secs</span>
            <mat-hint
              >The duration for which you want to lease the license.
              {{ UNLIMITED_VALUE_ZERO_AND_MINUS_ONE }}
            </mat-hint>
            <mat-error>{{
              getControlError(formGroup, 'leaseDuration')
            }}</mat-error>
          </mat-form-field>
          <!-- Allow Client Lease Duration -->
          <span>
            <mat-checkbox formControlName="allowClientLeaseDuration"
              >Allow Client Lease Duration
            </mat-checkbox>
            <label
              >If checked, clients are allowed to override the lease
              duration.</label
            >
          </span>
          } @if (selectedLicenseType === 'on-premise-floating') {
          <!-- Allowed Floating Clients -->
          <mat-form-field>
            <mat-label>Allowed Floating Clients</mat-label>
            <input
              type="number"
              formControlName="allowedFloatingClients"
              matInput
              [required]="selectedLicenseType === 'on-premise-floating'"
            />
            <mat-error>{{
              getControlError(formGroup, 'allowedFloatingClients')
            }}</mat-error>
            <mat-hint
              >The number of concurrent clients allowed to run by the
              LexFloatServer instance.</mat-hint
            >
          </mat-form-field>
          <!-- Leasing Strategy -->
          <mat-form-field>
            <mat-label>Leasing Strategy</mat-label>
            <mat-select formControlName="leasingStrategy" required>
              <mat-option value="per-machine">Per-Machine</mat-option>
              <mat-option value="per-instance">Per-Instance</mat-option>
            </mat-select>
            <mat-hint>
              The leasing strategy followed by the clients of LexFloatServer.
            </mat-hint>
            <mat-error>{{
              getControlError(formGroup, 'leasingStrategy')
            }}</mat-error>
          </mat-form-field>
          <!-- Lease Duration -->
          <mat-form-field>
            <mat-label>Lease Duration</mat-label>
            <input
              autocomplete="off"
              type="number"
              formControlName="leaseDuration"
              matInput
              required
            />
            <span matSuffix>secs</span>
            <mat-hint
              >The duration for which the clients of LexFloatServer are allowed
              to checkout a license. Set 0 to honor the lease duration set in
              the LexFloatServer config file.</mat-hint
            >
            <mat-error>{{
              getControlError(formGroup, 'leaseDuration')
            }}</mat-error>
          </mat-form-field>
          <!-- Allow Server Lease Duration -->
          <span>
            <mat-checkbox formControlName="allowServerLeaseDuration"
              >Allow Server Lease Duration
            </mat-checkbox>
            <label
              >If checked, LexFloatServer is allowed to override the lease
              duration.</label
            >
          </span>
          }
          <hr />
          <!-- Validity -->
          @if(validityMethods === 'lifetime'){
          <label>Validity</label>
          }
          <mat-radio-group
            (change)="handleValidityMethodChange($event)"
            [selected]="
              formGroup.get('validity')?.value === 0 ? lifetime : days
            "
          >
            <mat-radio-button #days value="days">Valid For</mat-radio-button>
            <mat-radio-button value="lifetime" #lifetime
              >Lifetime</mat-radio-button
            >
          </mat-radio-group>

          @if(validityMethods === 'lifetime'){
          <input hidden [value]="0" formControlName="validity" />
          } @else {
          <mat-form-field>
            <mat-label>Validity</mat-label>
            <ctx-custom-validity-input
              [mindate]="tommorow"
              [method]="validityMethods"
              formControlName="validity"
            ></ctx-custom-validity-input>
            <mat-hint>
              {{
                formGroup.get('validity')?.value === '0'
                  ? 'License valid for lifetime'
                  : ''
              }}
            </mat-hint>
            <mat-error>{{ getControlError(formGroup, 'validity') }}</mat-error>
          </mat-form-field>
          }

          <!-- Allowed Activations -->
          <mat-form-field>
            <mat-label>Allowed Activations</mat-label>
            <input
              autocomplete="off"
              type="number"
              formControlName="allowedActivations"
              matInput
            />
            @if(selectedLicenseType === 'on-premise-floating'){
            <mat-hint
              >The number of simultaneous activations of the LexFloatServer
              license. {{ UNLIMITED_VALUE_ZERO_AND_MINUS_ONE }}</mat-hint
            >
            } @else {
            <mat-hint
              >The number of simultaneous devices this license can be used on.
              {{ UNLIMITED_VALUE_ZERO_AND_MINUS_ONE }}</mat-hint
            >
            }
            <mat-error>{{
              getControlError(formGroup, 'allowedActivations')
            }}</mat-error>
          </mat-form-field>

          <!-- Allowed Deactivations -->
          <mat-form-field>
            <mat-label>Allowed Deactivations</mat-label>
            <input
              autocomplete="off"
              type="number"
              formControlName="allowedDeactivations"
              [readonly]="selectedLicenseType === 'hosted-floating'"
              matInput
            />
            <mat-error>{{
              getControlError(formGroup, 'allowedDeactivations')
            }}</mat-error>
            @if (selectedLicenseType !== 'hosted-floating') {
            <mat-hint>
              Allowed number of deactivations for the
              {{
                selectedLicenseType === 'on-premise-floating'
                  ? 'LexFloatServer '
                  : ''
              }}license. {{ UNLIMITED_VALUE_MINUS_ONE }}
            </mat-hint>
            } @if (selectedLicenseType === 'hosted-floating') {
            <mat-hint
              >Hosted-Floating licenses have no limit on
              deactivations.</mat-hint
            >
            }
          </mat-form-field>

          <!-- Metadata and Metering -->
          <hr />
          <h2>Metadata and Meter Attributes</h2>

          <!-- Required Metadata Keys -->
          <mat-form-field>
            <mat-label>Required Metadata Keys</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="requiredMetadataKeys"
              matInput
            />
            <mat-error>{{
              getControlError(formGroup, 'requiredMetadataKeys')
            }}</mat-error>
            <mat-hint
              >Comma separated names of metadata keys which are created for
              every license.</mat-hint
            >
          </mat-form-field>

          <!-- Required Meter Attributes -->
          <mat-form-field>
            <mat-label>Required Meter Attributes</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="requiredMeterAttributes"
              matInput
            />
            <mat-error>{{
              getControlError(formGroup, 'requiredMeterAttributes')
            }}</mat-error>
            <mat-hint
              >Comma separated names of meter attributes which are created for
              every license.</mat-hint
            >
          </mat-form-field>
        </section>
      </ctx-tab>

      <ctx-tab
        label="Advanced"
        icon="ADVANCED_SETTINGS"
        [stepControl]="advancedTab"
      >
        <section class="flex flex-col items-stretch gap-4">
          <h2>Advanced License Parameters</h2>

          <!-- Fingerprint Matching Strategy -->
          <mat-form-field>
            <mat-label>Fingerprint Matching Strategy</mat-label>
            <mat-select formControlName="fingerprintMatchingStrategy">
              <mat-option value="exact">Exact</mat-option>
              <mat-option value="fuzzy">Fuzzy</mat-option>
              <mat-option value="loose">Loose</mat-option>
            </mat-select>
            @if (formGroup.value.fingerprintMatchingStrategy === 'exact') {
            <mat-hint>
              If there is a minor change in the hardware, the fingerprint will
              not be accepted, and the machine will be treated as a different
              machine.</mat-hint
            >
            } @if (formGroup.value.fingerprintMatchingStrategy === 'fuzzy') {
            <mat-hint
              >Different scores for different hardware components determine
              whether the fingerprint matches. This is the recommended
              strategy.</mat-hint
            >
            } @if (formGroup.value.fingerprintMatchingStrategy === 'loose') {
            <mat-hint
              >This strategy is similar to fuzzy but with a much lower threshold
              value.</mat-hint
            >
            }
            <mat-error>{{
              getControlError(formGroup, 'fingerprintMatchingStrategy')
            }}</mat-error>
          </mat-form-field>

          <!-- Expiration Strategy -->
          <mat-form-field>
            <mat-label>Expiration Strategy</mat-label>
            <mat-select formControlName="expirationStrategy">
              <mat-option value="immediate">Immediate</mat-option>
              <mat-option value="delayed">Delayed</mat-option>
            </mat-select>
            @if (formGroup.value.expirationStrategy === 'immediate') {
            <mat-hint
              >The license starts expiring right after it is created.</mat-hint
            >
            } @if (formGroup.value.expirationStrategy === 'delayed') {
            <mat-hint
              >The license starts expiring after the license is activated the
              first time.</mat-hint
            >
            }
            <mat-error>{{
              getControlError(formGroup, 'serverSyncInterval')
            }}</mat-error>
          </mat-form-field>

          <!-- Expiring soon event offset -->
          <mat-form-field>
            <mat-label>'Expiring Soon' Event Offset</mat-label>
            <input
              autocomplete="off"
              type="number"
              formControlName="expiringSoonEventOffset"
              matInput
            />
            <span matSuffix>days</span>
            <mat-error>{{
              getControlError(formGroup, 'expiringSoonEventOffset')
            }}</mat-error>
            <mat-hint
              >The number of days before which the 'license.expiring-soon'
              Webhook and Email Template event will be triggered.</mat-hint
            >
          </mat-form-field>

          <!-- Key Pattern -->
          <mat-form-field>
            <mat-label>Key Pattern</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="keyPattern"
              matInput
            />
            <mat-error>{{
              getControlError(formGroup, 'keyPattern')
            }}</mat-error>
            <mat-hint>Regex for the license key pattern.</mat-hint>
          </mat-form-field>

          <!-- Server Sync -->
          <hr />
          <h2>Server Sync</h2>

          <!-- SSI -->
          <mat-form-field>
            <mat-label>Server Sync Interval</mat-label>
            <input
              autocomplete="off"
              type="number"
              formControlName="serverSyncInterval"
              matInput
            />
            <span matSuffix>seconds</span>
            <mat-error>{{
              getControlError(formGroup, 'serverSyncInterval')
            }}</mat-error>
            <mat-hint
              >The interval at which the LexActivator SDK syncs with Cryptlex
              servers.</mat-hint
            >
          </mat-form-field>

          <!-- SSGP -->
          <mat-form-field>
            <mat-label>Server Sync Grace Period</mat-label>
            <input
              autocomplete="off"
              type="number"
              formControlName="serverSyncGracePeriod"
              matInput
            />
            <span matSuffix>days</span>
            <mat-error>{{
              getControlError(formGroup, 'serverSyncGracePeriod')
            }}</mat-error>
            <mat-hint
              >The amount of time the application is allowed to continue without
              connecting to Cryptlex servers. The LexActivator SDK returns a
              LA_GRACE_PERIOD_OVER status code after this period.
              {{ UNLIMITED_VALUE_MINUS_ONE }}</mat-hint
            >
          </mat-form-field>

          <!-- Operating System -->
          <hr />
          <h2>Operating System</h2>
          <!-- Allowed Clock Offset -->
          <mat-form-field>
            <mat-label>Allowed Clock Offset</mat-label>
            <input
              autocomplete="off"
              type="number"
              formControlName="allowedClockOffset"
              matInput
            />
            <span matSuffix>seconds</span>
            <mat-error>{{
              getControlError(formGroup, 'allowedClockOffset')
            }}</mat-error>

            <mat-hint>{{ UNLIMITED_VALUE_MINUS_ONE }}</mat-hint>
          </mat-form-field>
          <!-- User Locked -->
          <span>
            <mat-checkbox formControlName="userLocked"
              >User Locked</mat-checkbox
            >
            <label
              >If checked, license will be locked to the Operating System
              user.</label
            >
          </span>
          <!-- Allow VM Activation -->
          <span>
            <mat-checkbox formControlName="allowVmActivation"
              >Allow VM Activation</mat-checkbox
            >
            <label
              >If checked, activation on virtual machines is allowed.</label
            >
          </span>

          <!-- Allow Container Activation -->
          <span>
            <mat-checkbox formControlName="allowContainerActivation"
              >Allow Container Activation
            </mat-checkbox>
            <label>If checked, activation on containers is allowed.</label>
          </span>

          <!-- Geolocation -->
          <hr />
          <h2>Geolocation</h2>
          <!-- Disable Geolocation -->
          <span>
            <mat-checkbox
              formControlName="disableGeoLocation"
              (change)="handleDisableGeolocationChange($event)"
              >Disable Geolocation</mat-checkbox
            >
            <label
              >If checked, geolocation information is not stored with
              Cryptlex.</label
            >
          </span>

          <!-- Allowed Countries -->
          <mat-form-field>
            <mat-label>Allowed Countries</mat-label>
            <mat-select
              formControlName="allowedCountries"
              multiple
              [disabled]="formGroup.value.disableGeoLocation"
            >
              @for (country of ALL_COUNTRIES | keyvalue : originalOrder; track
              country) {
              <mat-option [value]="country.key">
                {{ country.value }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{
              getControlError(formGroup, 'allowedCountries')
            }}</mat-error>
            <mat-hint>Country is determined by IP addresses.</mat-hint>
          </mat-form-field>

          <!-- Disallowed Countries -->
          <mat-form-field>
            <mat-label>Disallowed Countries</mat-label>
            <mat-select
              formControlName="disallowedCountries"
              multiple
              [disabled]="formGroup.value.disableGeoLocation"
            >
              @for (country of ALL_COUNTRIES | keyvalue : originalOrder; track
              country) {
              <mat-option [value]="country.key">
                {{ country.value }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{
              getControlError(formGroup, 'disallowedCountries')
            }}</mat-error>
            <mat-hint
              >Country is determined by IP addresses. If a country is set in
              both Allowed and Disallowed, Disallowed is given
              preference.</mat-hint
            >
          </mat-form-field>

          <!-- IP -->
          <hr />
          <h2>IP Addresses</h2>

          <!-- Allowed IP Ranges -->
          <mat-form-field>
            <mat-label>Allowed IP Ranges</mat-label>
            <input type="text" formControlName="allowedIpRanges" matInput />
            <mat-error>{{
              getControlError(formGroup, 'allowedIpRanges')
            }}</mat-error>
            <mat-hint
              >Comma separated IP ranges in CIDR notation to allow activating
              this license from.</mat-hint
            >
          </mat-form-field>

          <!-- Allowed IP Addresses -->
          <mat-form-field>
            <mat-label>Allowed IP Addresses</mat-label>
            <input type="text" formControlName="allowedIpAddresses" matInput />
            <mat-error>{{
              getControlError(formGroup, 'allowedIpAddresses')
            }}</mat-error>
            <mat-hint
              >Comma separated IP addresses to allow activating this license
              from.</mat-hint
            >
          </mat-form-field>

          <!-- Disallowed IP Addresses -->
          <mat-form-field>
            <mat-label>Disallowed IP Addresses</mat-label>
            <input
              type="text"
              formControlName="disallowedIpAddresses"
              matInput
            />
            <mat-error>{{
              getControlError(formGroup, 'disallowedIpAddresses')
            }}</mat-error>
            <mat-hint
              >Comma separated IP addresses to disallow activating this license
              from.</mat-hint
            >
          </mat-form-field>
        </section>
      </ctx-tab>
    </ctx-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
