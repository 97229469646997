import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ResourceName } from 'utils';
import { FilterableProperties } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { ProductVersionFormComponent } from './product-version-form/product-version-form.component';
import { SEARCH_STRINGS } from 'utils';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ProductVersionService extends ResourceTableService {
  override resourceApiPath = '/v3/product-versions';
  override resourceName: ResourceName = 'product-version';

  override tableSearchPlaceholder = SEARCH_STRINGS.name;
  override tableEmptyMessage =
    'You have not defined any product versions. Product Versions are sets of Feature Flags that manage flavours of your Product like Lite, Pro, and Ultimate.';
  override selections = new SelectionModel<any>(true, []);
  override filterableProperties: FilterableProperties = {
    productId: 'product',
    name: 'objectString',
  };

  override actions: TableActions = {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.createAllowed;
        },
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };

  override get createAllowed() {
    return (
      this.writeAllowed &&
      this.permissionsService.allowedAction('product', 'read')
    );
  }
  override creationComponent = ProductVersionFormComponent;
  override updationComponent = ProductVersionFormComponent;

  override columns: TableColumn[] = [
    { displayType: 'name', property: 'name' },
    { displayType: 'text', property: 'displayName' },
    { displayType: 'truncateText', property: 'description' },
    { displayType: 'product', property: 'productId' },
    { displayType: 'featureFlags', property: 'featureFlags' },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];
  override _columnIdsToDisplay: string[] = [
    'name',
    'description',
    'productId',
    'featureFlags',
  ];
  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }
}
