import { Component, Input, OnInit } from '@angular/core';

import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { CtxValidators } from 'utils';
import { CtxForm } from '../models/form.model';
import { DataCacheService } from '../../_services/data-cache.service';

@Component({
  selector: 'ctx-tag-input',
  standalone: true,
  imports: [MatSelectModule, ReactiveFormsModule],
  templateUrl: './tag-input.component.html',
})
export class CtxTagInputComponent extends CtxForm implements OnInit {
  /** FormControl */
  @Input({ required: true }) control: AbstractControl<any, any> | null;
  constructor(public dataCacheService: DataCacheService) {
    super();
  }
  tags = this.dataCacheService.getCachedValues('tag') || [];
  tagControl: FormControl;
  handleTagSelection(event: MatSelectChange) {
    this.control?.patchValue(event.value);
  }
  ngOnInit(): void {
    this.tagControl = new FormControl(this.control?.value, CtxValidators.tags);
  }
}
