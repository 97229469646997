import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { nanoid } from 'nanoid';
import { CtxForm } from 'ng-ui';
import { ResourceUpdateFormData } from 'utils';
import { DataCacheService } from 'ng-ui';
import { CtxButtonComponent } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-send-test-email',
  templateUrl: './send-test-email.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    MatDialogContent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class SendTestEmailComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData,
    public dialogRef: MatDialogRef<SendTestEmailComponent>,
    private fb: FormBuilder,
    private dataCacheService: DataCacheService
  ) {
    super();
  }

  profile = this.dataCacheService.getCachedValues('profile');
  /** Default values for the placeholders in the automated email */
  TEST_EMAIL_MODEL = {
    user: {
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      email: this.profile.email,
      name: this.profile.name,
      role: this.profile.role,
      company: '',
    },
    license: { key: nanoid() },
    product: { displayName: 'GreatestProductPro', name: 'GreatProduct' },
  };
  ngOnInit(): void {
    this.formGroup = this.fb.group({});
  }
}
