import { CtxIcons, SegmentResourceName } from 'utils';
import { AuthnService } from '../_services/authn.service';

export type RouteInfo<T> = {
  path: T;
  label: string;
  icon: CtxIcons;
  hidden?: (service: AuthnService) => boolean;
  segmentName?: SegmentResourceName;
};

export type RouteNode<BasePaths, LeafPaths> = RouteInfo<BasePaths> & {
  children?: RouteNode<LeafPaths, null>[];
};

export abstract class CtxRoutes<T1, T2> {
  abstract routes: RouteNode<T1, T2>[];

  getNode(baseNodePath: T1): RouteNode<T1, T2> {
    const baseNode = this.routes.find((_baseNode) => {
      return _baseNode.path === baseNodePath;
    });

    if (baseNode) {
      return baseNode;
    } else {
      throw Error(`Could not find base path for ${baseNodePath}`);
    }
  }

  /**
   * DFS on routes.
   * @param leafNodePath
   * @returns Both baseNode and leafNode.
   */
  getLeaf(leafNodePath: T2) {
    let leafNode: RouteNode<T2, null> | undefined;

    const baseNode = this.routes.find((baseNode) => {
      if (baseNode.children) {
        leafNode = baseNode.children.find((_leafNode) => {
          return _leafNode.path === leafNodePath;
        });
        return leafNode && true;
      } else {
        return false; // No children, not a match
      }
    });

    if (!baseNode || !leafNode) {
      // This will be thrown at compile-time and MAY ALSO be thrown at run-time.
      // TODO (mumin-khan) ensure this doesn't break anything.
      throw Error(`Error while looking for leafNode ${leafNodePath}`);
    } else {
      return { baseNode, leafNode };
    }
  }

  /**
   *
   * @param title route title name
   * @returns add | Cryptlex to the title
   */
  getRouteTitle(label: string): string {
    return `${label} | Cryptlex`;
  }
}
