import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalNotificationSnackbarComponent } from 'admin-portal/common/global-notification-snackbar/global-notification-snackbar.component';

const DEFAULT_NOTIFICATION_DURATION = 3000;
/**
 * This service is used to give global notifications using Snackbars. This pattern should be avoided as much as possible.
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalNotificationService {
  constructor(private snackbar: MatSnackBar) {}

  notify(
    message: string,
    duration: number = DEFAULT_NOTIFICATION_DURATION,
    action: string
  ) {
    return this.snackbar.openFromComponent(
      GlobalNotificationSnackbarComponent,
      {
        data: { message, action },
        duration,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      }
    );
  }

  clear() {
    this.snackbar.dismiss();
  }
}
