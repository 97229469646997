<h2 mat-dialog-title>
  {{ currentAccountStatus === 'active' ? 'Deactivate' : 'Activate' }} Account
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}
<mat-dialog-content>
  <ctx-stepper [linear]="true" #stepper>
    @if (currentAccountStatus === 'active') {
    <ctx-step label="Declaration">
      <div class="py-4">
        <p>
          If you deactivate your account it will be put in an inactive mode.
          This means that :
        </p>
        <ul class="list-disc pl-6">
          <li>You will <strong>no longer be charged</strong>.</li>
          <li>
            You will still be able to view all your products and licenses.
          </li>
          <li>
            Your customers will still be able to use the licenses that have
            already been activated.
          </li>
          <li>You won&apos;t be able to modify anything.</li>
        </ul>
        <p>
          Additionally, if you do not log in to your account for up to one year,
          your account will be permanently deleted. However,
          <strong>
            you can re-activate your account at any time before this period
            ends.
          </strong>
        </p>
      </div>
    </ctx-step>
    <ctx-step label="Confirmation" [stepControl]="formGroup">
      <p class="my-4">
        Enter the text <code class="select-none">I am absolutely sure</code> in
        the field below to proceed.
      </p>
      <form
        (ngSubmit)="submit(formValue, dialogData, dialogRef)"
        [formGroup]="formGroup"
      >
        <mat-form-field class="w-full">
          <mat-label>Confirmation Text</mat-label>
          <input
            autocomplete="off"
            type="text"
            formControlName="deactivationConfirmationText"
            matInput
            required
          />
          <mat-error>{{
            getControlError(formGroup, 'deactivationConfirmationText')
          }}</mat-error>
        </mat-form-field>
      </form>
    </ctx-step>
    } @else {

    <ctx-step #singleStep>
      To reactivate your account, please click the button below. Once your
      account is reactivated, you'll be able to activate licenses and resume all
      other Cryptlex services within this account.
    </ctx-step>

    }
  </ctx-stepper>
</mat-dialog-content>
<mat-dialog-actions>
  @if (stepper.selectedIndex === 0 && currentAccountStatus === 'active') {
  <button matDialogClose ctx-button--secondary>Close</button>
  <button ctx-button--primary (click)="stepper.next()">Continue</button>
  } @else { @if (currentAccountStatus === 'active') {
  <button
    ctx-button--primary
    color="warn"
    type="submit"
    [disabled]="formGroup.invalid || this.formStates['submit']"
    [loading]="this.formStates['submit']"
  >
    Deactivate
  </button>
  } @else {
  <button
    ctx-button--primary
    (click)="submit(formValue, dialogData, dialogRef)"
    [disabled]="formGroup.invalid || this.formStates.disabled"
    [loading]="this.formStates.submit"
  >
    Activate
  </button>
  }
  <button
    matDialogClose
    ctx-button--secondary
    [disabled]="this.formStates['submit']"
  >
    Close
  </button>
  }
</mat-dialog-actions>
