import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CtxPlanResponse, reloadAfterSuccess } from 'utils';
import { ResourceName } from 'utils';
import { CtxFormMode, ResourceUpdateFormData } from 'utils';
import {
  ChangePasswordFormComponent,
  CryptlexApiService,
  ProfileUpdateFormComponent,
} from 'ng-ui';
import { DataCacheService } from 'ng-ui';

import { ResourceService } from 'ng-ui';
import { GoogleSsoFormComponent } from 'ng-ui';
import { TwoFactorFormComponent } from 'ng-ui';
import { PermissionsService } from './permissions.service';

/**
 * ProfileService does not extend ResourceTableService because ResourceTableService is primarily used to build tables.
 * Profile is a single UserResponseObject.
 */
@Injectable({
  providedIn: 'root',
})
export class ProfileService extends ResourceService {
  override resourceApiPath = '/v3/me';
  override resourceName: ResourceName = 'profile';

  constructor(
    apiService: CryptlexApiService,
    dataCacheService: DataCacheService,
    private dialog: MatDialog,
    private router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dataCacheService, permissionsService);
  }

  get currentPlan(): CtxPlanResponse | undefined {
    const profile = this.dataCacheService.getCachedValues('profile');
    const plans: CtxPlanResponse[] = this.dataCacheService
      .getCachedValues('plan')
      ?.filter((plan: CtxPlanResponse) => {
        return plan.deprecated === false;
      });
    return plans?.find((plan: CtxPlanResponse) => {
      return plan.id === profile?.planId;
    });
  }

  /** ID is not required in the /v3/me endpoint */
  override update(_id: string, body: any): Promise<HttpResponse<any>> {
    return super.update('', body);
  }

  /**
   * Launches a dialog that updates the user profile.
   */
  async launchUpdationDialog(): Promise<void> {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      ProfileUpdateFormComponent,
      {
        data: {
          mode: CtxFormMode.Update,
          resource: this.dataCacheService.getCachedValues('profile'),
          update: this.update.bind(this),
        },
      }
    );

    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }

  /**
   * Launch a dialog that allows the profile owner to change the current password.
   */
  launchPasswordChangeDialog(): void {
    this.dialog.open<any, ResourceUpdateFormData>(ChangePasswordFormComponent, {
      data: {
        resource: this.dataCacheService.getCachedValues('profile'),
        update: this.updatePassword.bind(this),
        mode: CtxFormMode.Update,
      },
    });
  }

  /** Both auth service and this service have hardcoded base URLs. */
  updatePassword(id: string, body: any) {
    return this.apiService.post(`/v3/users/${id}/update-password`, body);
  }

  /**
   * Launch a dialog that allows configuring 2FA.
   */
  async launchTwoFactorEnableDialog(): Promise<void> {
    const twoFactorDialog = this.dialog.open<any, ResourceUpdateFormData>(
      TwoFactorFormComponent,
      {
        data: {
          resource: this.dataCacheService.getCachedValues('profile'),
          update: this.update.bind(this),
          mode: CtxFormMode.Update,
        },
      }
    );

    await reloadAfterSuccess(twoFactorDialog.afterClosed(), this.router);
  }

  /**
   * Launch a dialog that allows configuring 2FA.
   */
  async launchGoogleSsoDialog(): Promise<void> {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      GoogleSsoFormComponent,
      {
        data: {
          resource: this.dataCacheService.getCachedValues('profile'),
          mode: CtxFormMode.Update,
          update: this.update.bind(this),
        },
      }
    );
    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }

  /**
   * @returns Promise that resolves to 2FA Secret from Web API.
   */
  async getTwoFactorSecret() {
    return this.apiService.post(
      `${this.resourceApiPath}/2fa-secret`,
      undefined
    );
  }
}
