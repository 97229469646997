<h2 mat-dialog-title>Add Tag list</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(this.formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <p>The previous tag(s) from the license(s) will be removed.</p>

    <ctx-tag-input [control]="formGroup.get('tags')"></ctx-tag-input>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      actionIcon="TAG"
      type="submit"
    >
      Tag
    </button>
  </mat-dialog-actions>
</form>
