import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import {
  CommonModule,
  NgOptimizedImage,
  isPlatformBrowser,
} from '@angular/common';
import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { projectEnv } from 'utils';
import { ThemeManagerService } from '../../_services/theme-manager.service';
import { getCustomConfigValue } from 'utils';

const CRYPTLEX_LOGO_URL = '/assets/cryptlex-logo.svg';
@Component({
  selector: 'ctx-company-logo',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './company-logo.component.html',
})
export class CtxCompanyLogoComponent {
  get logoUrl() {
    let url = CRYPTLEX_LOGO_URL;
    if (isPlatformBrowser(this.platformId)) {
      const customLogo = getCustomConfigValue('logoUrl');
      if (customLogo) {
        url = customLogo;
      }
    }
    return url;
  }

  /** URL that points to the logo */
  private _logoUrl: string = CRYPTLEX_LOGO_URL;

  get isLogoCustom() {
    return projectEnv.get('projectName') !== 'admin-portal';
  }

  constructor(
    public theme: ThemeManagerService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  /**
   * Height of the logo
   */
  @Input()
  get height(): number {
    return this._height;
  }
  set height(value: NumberInput) {
    this._height = coerceNumberProperty(value);
  }
  private _height = 24;
}
