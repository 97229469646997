<h2 mat-dialog-title>Send Test Email</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}

<!-- Content -->
<mat-dialog-content>
  <section class="flex flex-col gap-4">
    <p>
      Pressing the send button will send a message to your email based on the
      <strong>{{ dialogData.resource.name }}</strong> automated email.
    </p>
  </section>
</mat-dialog-content>

<!-- Buttons -->
<mat-dialog-actions>
  <button matDialogClose ctx-button--secondary [disabled]="formStates.disabled">
    Close
  </button>
  <button
    ctx-button--primary
    [disabled]="formStates.disabled"
    [loading]="formStates.submit"
    type="button"
    (click)="submit(TEST_EMAIL_MODEL, dialogData, dialogRef)"
  >
    Send
  </button>
</mat-dialog-actions>
