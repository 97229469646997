import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxDynamicForm } from 'ng-ui';

import { copyToClipboard } from 'utils';
import { DataCacheService } from 'ng-ui';
import { environment } from 'utils';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CtxButtonComponent } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-saml-sso-form',
  templateUrl: './saml-sso-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    CtxButtonComponent,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogActions,
    MatDialogClose,
  ],
})
export class SamlSsoFormComponent extends CtxDynamicForm implements OnInit {
  constructor(
    fb: FormBuilder,
    public dataCacheService: DataCacheService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    public dialogRef: MatDialogRef<SamlSsoFormComponent>
  ) {
    super(fb);
  }

  returnUrls: string[] = [];
  /** Account details from DataCache */
  accountDetails = this.dataCacheService.getCachedValues('account');
  roles = this.dataCacheService.getCachedValues('role');
  certificateUrl: any;
  /** https://knowledge.broadcom.com/external/article/247107/understanding-entity-id-and-reply-url-fo.html
   */
  entityId = `https://${this.accountDetails.accountAlias}.cryptlex.com`;

  acsUrl = `${environment.get('apiBaseUrl')}/v3/accounts/login/saml/${
    this.accountDetails.accountAlias
  }/acs`;
  samlRoleMappingsFields: FormArray;
  _createNewSamlRoleMapping() {
    return this.fb.group({
      identityProviderRole: '',
      serviceProviderRole: '',
    });
  }

  ngOnInit(): void {
    const currentUrl = `https://${window.location.host}`;
    if (window.location.hostname !== 'localhost') {
      this.returnUrls.push(currentUrl);
    }

    if (environment.get('name') !== 'on-premise') {
      this.returnUrls.push(
        `https://${this.accountDetails.accountAlias}.customer.cryptlex.com`
      );
      this.returnUrls.push(
        `https://${this.accountDetails.accountAlias}.reseller.cryptlex.com`
      );
      if (this.accountDetails.customerPortalDomain) {
        this.returnUrls.push(
          `https://${this.accountDetails.customerPortalDomain}`
        );
      }
      if (this.accountDetails.resellerPortalDomain) {
        this.returnUrls.push(
          `https://${this.accountDetails.resellerPortalDomain}`
        );
      }
    }

    this.formGroup = this.fb.group({
      entityId: [
        this.getInitialControlValue('entityId', this.dialogData, this.entityId),
        Validators.maxLength(2048),
      ],
      assertionConsumerServiceUrl: [
        this.getInitialControlValue(
          'assertionConsumerServiceUrl',
          this.dialogData,
          this.acsUrl
        ),
        Validators.maxLength(2048),
      ],
      returnUrl: [
        this.getInitialControlValue(
          'returnUrl',
          this.dialogData,
          this.returnUrls[0]
        ),
      ],
      metadataUrl: [
        this.getInitialControlValue('metadataUrl', this.dialogData) || '',
        Validators.maxLength(2048),
      ],
      autoProvisionUsers: [
        this.getInitialControlValue(
          'autoProvisionUsers',
          this.dialogData,
          false
        ),
      ],
      enabled: [this.getInitialControlValue('enabled', this.dialogData, true)],
      samlRoleMappings: this.fb.array(
        this.getInitialFormArrayValue('samlRoleMappings', this.dialogData) || []
      ),
    });

    this.samlRoleMappingsFields = this.formGroup.get(
      'samlRoleMappings'
    ) as FormArray;
  }
  copyToClipboard = copyToClipboard;

  createRoleMapping(checked: boolean) {
    if (checked && this.samlRoleMappingsFields.length === 0) {
      this.addFormArrayField(
        this.samlRoleMappingsFields,
        this._createNewSamlRoleMapping()
      );
    }
  }
}
