import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CTX_DEFAULT_MODULE_IMPORTS, CTX_DEFAULT_PROVIDERS } from 'ng-ui';
import { HighlightModule } from 'ngx-highlightjs';
import { routes } from './app.routes';
import { highlightJsDefaults } from './providers.config';
import { AppUpdateService } from './services/app-update.service';
import { Angulartics2Module } from 'angulartics2';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ...CTX_DEFAULT_MODULE_IMPORTS,
      HighlightModule,
      Angulartics2Module.forRoot({
        pageTracking: {
          clearIds: true,
        },
      }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      })
    ),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({ onSameUrlNavigation: 'reload' })
    ),
    ...CTX_DEFAULT_PROVIDERS,
    highlightJsDefaults,
    AppUpdateService,
  ],
};
