import { HttpResponse } from '@angular/common/http';
import { AccountAliasResponse, ApiQueryParameters } from './api.types';
import { AlertType } from './alert.types';
import { ResourceName } from '../lib/resource-names';
import { Filter } from './filter.types';

/**
 * After processing a message sent by the API, this is the format it is given to components.
 * It is rather primitive and open ot be expanded.
 */
export type ApiMessage = {
  text: string;
  alertLevel: AlertType;
};

/**
 * Error codes that are returned when searching errors in Reactive Forms
 */
export type FormErrorCodes =
  | 'required'
  | 'min'
  | 'max'
  | 'email'
  | 'minlength'
  | 'maxlength'
  | 'pattern'
  | string;

/**
 * Convention used when dealing with FormErrorData. This is done because the form errors
 * return an object at runtime where the key to access the error is not known. In this case,
 * the error object is converted to an Array where the keys can be accessed systematically.
 */
export type FormErrorData = [errorCode: FormErrorCodes, errorData: any];

/**
 * Enum defines the modes of the forms
 */
export enum CtxFormMode {
  'Create' = 'Create',
  'Update' = 'Update',
  'Patch' = 'Patch',
}

/**
 * All creation forms are passed data in this format.
 */
export type ResourceCreateFormData = {
  /**
   * This is useful when forms are re-used for both creation and updation. This string is readily usable
   * in templates.
   */
  mode: CtxFormMode.Create;
  create: (resource: any) => Promise<HttpResponse<any>>;
};

/**
 * All updation forms are passed data in this format.
 */
export type ResourceUpdateFormData = {
  mode: CtxFormMode.Update;
  update: (id: string, resource: any) => Promise<HttpResponse<any>>;
  resource: any;
};

/**
 * These forms make a POST/PATCH request with just the ID paramenter.
 */
export type ResourcePatchFormData = {
  mode: CtxFormMode.Patch;
  patch: (id: string) => Promise<HttpResponse<any>>;
  resource: any;
};

/** All export forms are passed this data. */
export type ResourceExportFormData = {
  resourceName: ResourceName;
  export: (query: ApiQueryParameters) => Promise<any>;
  query: ApiQueryParameters;
  filters: Filter[];
  tableRowCount: number;
};

/** Form to delete resources */
export type ResourceDeleteFormData = {
  delete: (id: string) => Promise<any>;
  resources: any[];
  deletionMessage: string;
};

/**  */
export type ResourceUpdateDynamicFormData = ResourceUpdateFormData & {
  deleteMetadata?: (metadata: any) => Promise<HttpResponse<any>>;
  deleteMeterAttribute?: (meter: any) => Promise<HttpResponse<any>>;
  resetMeterAttribute?: (meter: any) => Promise<HttpResponse<any>>;
  deleteFile?: (fileId: string) => Promise<HttpResponse<any>>;
};

/**  Data for form to pick accountAlias */
export type AccountAliasesFormData = {
  accountAliases: AccountAliasResponse[];
  email: string;
};

/** Data for a dialog that only shows an resource or it's property */
export type ResourceViewDialogData = {
  /** Resource object */
  resource: any;
  /** Property that is shown in the dialog */
  property?: string;
  /** Label for the button */
  label?: string;
  /** Title for the dialog */
  title?: string;
};

export type SessionExpiryData = {
  mode: CtxFormMode.Create;
  create: (resource: any) => Promise<HttpResponse<any>>;
  accountAlias: string;
  password: string;
  loginState: loginState;
};

export type loginState = 'email' | 'sso' | 'gsi';
